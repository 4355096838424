// App.tsx

import React, { useEffect, useState, Suspense } from "react";
import { ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch as useReduxDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "./redux/store";
import { Action } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import { fetchUserProfile } from "./redux/actions/authActions";
import "./App.css";
import Cookies from "js-cookie";
import { Toaster } from "./components/ui/toaster";
import LoaderCivicSync from "./components/LoaderCivicSync";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { CLIENT_ID, DOMAIN, API_AUDIENCE } from "./config";

// Lazy load the components
const HeroText = React.lazy(() => import("./components/HeroText"));
const AdminPanelLayout = React.lazy(
  () => import("./components/admin-panel/admin-panel-layout")
);
const ContentLayout = React.lazy(
  () => import("./components/admin-panel/content-layout")
);
const SurveyAnswers = React.lazy(() => import("./pages/SurveyAnswers"));
const AuthenticationForm = React.lazy(
  () => import("./pages/AuthenticationForm")
);
const HomeChat = React.lazy(() => import("./pages/HomeChat"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Profile = React.lazy(() => import("./pages/Profile"));
const Poll = React.lazy(() => import("./pages/Poll"));
const Polls = React.lazy(() => import("./pages/Polls"));
const Profiles = React.lazy(() => import("./pages/Profiles"));
const NewPoll = React.lazy(() => import("./pages/NewPoll"));
const ManualPoll = React.lazy(() => import("./pages/ManualPoll"));
const InstallLink = React.lazy(() => import("./pages/InstallLink"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage")); // Import the 404 page

type AppDispatch = ThunkDispatch<RootState, void, Action<string>>;

function useDispatch() {
  return useReduxDispatch<AppDispatch>();
}

interface ProtectedComponentProps {
  component: ComponentType<any>;
  [key: string]: any;
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component,
  ...propsForComponent
}) => {
  const Cp = withAuthenticationRequired(component);
  return <Cp {...propsForComponent} />;
};

function AppWrapper() {
  return (
    <Router>
      <Auth0Provider
        domain={DOMAIN}
        clientId={CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: API_AUDIENCE,
        }}
      >
        <WebSocketProvider>
          <App />
        </WebSocketProvider>
      </Auth0Provider>
    </Router>
  );
}

const layout = Cookies.get("react-resizable-panels:layout");
const defaultLayout = layout ? JSON.parse(layout) : undefined;

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken("");
      }
    };

    if (!isLoading && isAuthenticated) {
      fetchToken();
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchUserProfile(token));
    }
  }, [token, isAuthenticated, dispatch]);

  useEffect(() => {
    if (isAuthenticated && token) {
      dispatch(fetchUserProfile(token));
      // navigate(location.pathname, { replace: true });
    }
  }, [token, isAuthenticated, dispatch, location.pathname, navigate]);

  return (
    <Suspense fallback={<LoaderCivicSync center={true} />}>
      <Routes>
        {/* Special Pages without layout */}
        {/* <Route path="/forms/:jobId/:secret" element={<ManualPoll />} /> */}
        <Route path="/install/:company/:secret" element={<InstallLink />} />
        <Route
          path="/install/:company/:secret/:step"
          element={<InstallLink />}
        />

        <Route
          path="/*"
          element={
            <AdminPanelLayout>
              <ContentLayout title="">
                <Routes>
                  <Route path="/" element={<NewPoll />} />
                  <Route path="/ht" element={<HeroText />} />
                  <Route path="/login" element={<AuthenticationForm />} />
                  <Route
                    path="/survey/:questionId"
                    element={<ProtectedComponent component={SurveyAnswers} />}
                  />
                  <Route
                    path="/chat/:chatId"
                    element={<ProtectedComponent component={HomeChat} />}
                  />
                  <Route
                    path="/settings"
                    element={<ProtectedComponent component={Settings} />}
                  />
                  <Route
                    path="/profiles/:agentId"
                    element={<ProtectedComponent component={Profile} />}
                  />
                  <Route
                    path="/polls/:jobId"
                    element={<ProtectedComponent component={Poll} />}
                  />
                  <Route
                    path="/polls"
                    element={<ProtectedComponent component={Polls} />}
                  />
                  <Route
                    path="/profiles"
                    element={<ProtectedComponent component={Profiles} />}
                  />
                </Routes>
              </ContentLayout>
              <Toaster />
            </AdminPanelLayout>
          }
        />

        {/* Catch-all route for any unmatched paths */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
}

export default AppWrapper;
