import {
  AuthActionTypes,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  UserData,
  FETCH_USER_PROFILE,
  FETCH_USER_VPN_STATE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
  LOGOUT,
  UPDATE_USER_PROFILE_REQUEST,
  UPDATE_USER_CERTIFICATE_REQUEST,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_FAILURE,
} from "../actions/authActions";

export interface AuthState {
  loading: boolean;
  loadingCertificate: boolean;
  userData?: UserData;
  error?: string | null | undefined;
  errorCode?: number | null | undefined;
}

const initialState: AuthState = {
  loading: false,
  loadingCertificate: false,
  userData: undefined,
  errorCode: undefined,
  error: undefined,
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    // Regular login actions
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        userData: undefined,
        error: action.payload,
      };

    // Google OAuth login actions
    case GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload, // This should be of type UserData
        errorCode: undefined,
        error: null,
      };

    case GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload, // This should also be of type UserData
        errorCode: undefined,
        error: null,
      };

    case GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_VPN_STATE:
      return {
        ...state,
        loadingCertificate: true,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        loadingCertificate: false,
        errorCode: undefined,
        error: null,
      };
    case FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        loadingCertificate: false,
        ...action.payload,
      };
    case UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_CERTIFICATE_REQUEST:
      return {
        ...state,
        loadingCertificate: true,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false,
        loadingCertificate: false,
        errorCode: 200,
        error: null,
      };
    case UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        loadingCertificate: false,
        ...action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        userData: undefined,
      };
    default:
      return state;
  }
};

export default authReducer;
