import { createStore, applyMiddleware, combineReducers, Action } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import questionReducer, { QuestionState } from "./reducers/questionReducer";
import { QuestionActionTypes } from "./actions/questionActions";
import { composeWithDevTools } from "@redux-devtools/extension";

import authReducer, { AuthState } from "./reducers/authReducer";
import { AuthActionTypes } from "./actions/authActions";

import chatReducer, { ChatState } from "./reducers/chatReducer";
import { ChatActionTypes } from "./actions/chatActions";

import insightReducer, { InsightState } from "./reducers/insightReducer";
import { InsightActionTypes } from "./actions/insightActions";

export interface RootState {
  question: QuestionState;
  chat: ChatState;
  auth: AuthState; // Add this line
  insight: InsightState;
}

// 2. Update the rootReducer
const rootReducer = combineReducers({
  question: questionReducer,
  auth: authReducer, // Add this line
  chat: chatReducer,
  insight: insightReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

// 3. Update AppThunk
export type AppThunk<ReturnType = Promise<void>> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  QuestionActionTypes | AuthActionTypes | ChatActionTypes | InsightActionTypes
>;
