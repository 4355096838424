import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from "react";
import { WEBSOCKET_URL } from "../config";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import {
  SEND_MESSAGE_FAILURE,
  SEND_MESSAGE_SUCCESS,
} from "../redux/actions/chatActions";

interface WebSocketProviderProps {
  children: ReactNode;
}

const WebSocketContext = createContext<any>(null);

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const tokenRef = useRef<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeWebSocket = async () => {
      if (!isAuthenticated) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        tokenRef.current = token;
        const chatUrl = `${WEBSOCKET_URL}/chat/ws?token=${token}`;
        const ws = new WebSocket(chatUrl);

        ws.onopen = () => {
          setIsConnected(true);
        };

        ws.onerror = (error: Event) => {
          console.error("WebSocket error:", error);
        };

        ws.onmessage = (message: MessageEvent) => {
          console.log("Received:", message.data);
          //dispatch(receiveMessage(message.data));
        };

        ws.onclose = (event: CloseEvent) => {
          setIsConnected(false);
          if (event.wasClean) {
            console.log(
              `WebSocket closed cleanly, code=${event.code}, reason='${event.reason}'`
            );
          } else {
            console.error(
              "WebSocket closed unexpectedly:",
              `code=${event.code}`,
              `reason='${event.reason}'`
            );
          }
          // Attempt to reconnect after a delay if the close was not clean
          console.log("Attempting to reconnect...");
          setTimeout(() => initializeWebSocket(), 1000); // 1-second delay before attempting to reconnect
        };

        setSocket(ws);
      } catch (error) {
        console.error("Error retrieving token", error);
      }
    };

    initializeWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [getAccessTokenSilently, isAuthenticated, dispatch]);

  const sendMessage = (messageData: any) => {
    if (isConnected && socket) {
      socket.send(JSON.stringify(messageData));
      dispatch({ type: SEND_MESSAGE_SUCCESS, payload: messageData });
    } else {
      dispatch({
        type: SEND_MESSAGE_FAILURE,
        payload: "An unknown error occurred.",
      });
    }
  };

  return (
    <WebSocketContext.Provider value={{ socket, isConnected, sendMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);
