import axios, { AxiosError } from "axios";
import { AppThunk } from "../store";
import { BASE_API_URL } from "../../config";
import { List } from "lodash";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_VPN_STATE = "FETCH_USER_VPN_STATE";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const UPDATE_USER_CERTIFICATE_REQUEST =
  "UPDATE_USER_CERTIFICATE_REQUEST";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE";

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: UserData; // This is the token
}

export interface LoginFailureAction {
  type: typeof LOGIN_FAILURE;
  payload: string; // Error message
}

export interface GoogleLoginRequestAction {
  type: typeof GOOGLE_LOGIN_REQUEST;
}

export interface GoogleLoginSuccessAction {
  type: typeof GOOGLE_LOGIN_SUCCESS;
  payload: UserData;
}

export interface GoogleLoginFailureAction {
  type: typeof GOOGLE_LOGIN_FAILURE;
  payload: string;
}

export interface UpdateUserProfileRequestAction {
  type: typeof UPDATE_USER_PROFILE_REQUEST;
}

export interface UpdateUserCertificateRequestAction {
  type: typeof UPDATE_USER_CERTIFICATE_REQUEST;
}

export interface UpdateUserProfileSuccessAction {
  type: typeof UPDATE_USER_PROFILE_SUCCESS;
  payload: UserData; // Assuming UserData is the type of your user data
}

export interface UpdateUserProfileFailureAction {
  type: typeof UPDATE_USER_PROFILE_FAILURE;
  payload: ErrorData; // Error message
}

export interface AuthData {
  token: string;
  userId: string;
  error?: string;
  loading: boolean;
}

export interface ErrorData {
  errorCode: number | null | undefined;
  error: string;
}

export interface UserData {
  token?: string;
  id?: string;
  fullname?: string;
  name?: string;
  bio?: string;
  email?: string;
  twitter_handle?: string;
  twitter_password?: string;
  keywords_news?: string;
  subreddits?: string;
  method?: string;
  year_of_birth?: number;
  country_of_birth?: string;
  gender?: string;
  education_level?: string;
  employment_status?: string;
  household_income?: string;
  marital_status?: string;
  interests?: string;
  plugin_id?: Array<ListPluginId> | undefined;
  api_key?: Array<ListPluginId> | undefined;
  vpn_active?: boolean;
}

export interface ListPluginId {
  plugin_id: string;
  active: boolean;
}

export interface FetchUserProfileAction {
  type: typeof FETCH_USER_PROFILE;
}

export interface FetchUserVpnStateAction {
  type: typeof FETCH_USER_VPN_STATE;
}

export interface FetchUserProfileSuccessAction {
  type: typeof FETCH_USER_PROFILE_SUCCESS;
  payload: UserData; // Define UserData type based on your data shape
}

export interface AuthError {
  errorCode: number | null | undefined;
  error: string;
}

export interface FetchUserProfileFailureAction {
  type: typeof FETCH_USER_PROFILE_FAILURE;
  payload: AuthError; // error message
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export const loginRequest = (): LoginRequestAction => ({
  type: LOGIN_REQUEST,
});

export const loginSuccess = (userData: UserData): LoginSuccessAction => ({
  type: LOGIN_SUCCESS,
  payload: userData,
});

export const loginFailure = (error: string): LoginFailureAction => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const googleLoginRequest = (): GoogleLoginRequestAction => ({
  type: GOOGLE_LOGIN_REQUEST,
});

export const googleLoginSuccess = (
  userData: UserData
): GoogleLoginSuccessAction => {
  // Set the token in local storage
  return {
    type: GOOGLE_LOGIN_SUCCESS,
    payload: userData,
  };
};

export const googleLoginFailure = (
  error: string
): GoogleLoginFailureAction => ({
  type: GOOGLE_LOGIN_FAILURE,
  payload: error,
});

export const logout = () => {
  return (dispatch: any) => {
    // Clear the token from local storage (assuming you have saved it there)
    localStorage.removeItem("token");

    // Dispatch the logout action to update Redux state
    dispatch({ type: LOGOUT });
  };
};

// Exporting a consolidated type for all authentication-related actions
export type AuthActionTypes =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | GoogleLoginRequestAction
  | GoogleLoginSuccessAction
  | GoogleLoginFailureAction
  | FetchUserProfileAction
  | FetchUserVpnStateAction
  | FetchUserProfileSuccessAction
  | FetchUserProfileFailureAction
  | LogoutAction
  | UpdateUserProfileRequestAction
  | UpdateUserCertificateRequestAction
  | UpdateUserProfileSuccessAction
  | UpdateUserProfileFailureAction;

// The googleAuth function that sends the tokenId to your backend and dispatches actions based on the response
export const googleAuth =
  (tokenId: string): AppThunk =>
  async (dispatch) => {
    dispatch(googleLoginRequest());
    try {
      const response = await axios.post(`${BASE_API_URL}/agent/verify-token`, {
        tokenId: tokenId, // Assuming `tokenId` contains the authorization code
      });
      console.log("response", response);
      if (response.data && response.status === 200) {
        console.log("response", response.data.payload);
        dispatch(
          googleLoginSuccess({
            ...response.data.payload,
          })
        );
      } else {
        dispatch(googleLoginFailure("Failed to authenticate with Google."));
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Error) {
        dispatch(googleLoginFailure(error.message));
      } else {
        dispatch(googleLoginFailure("An unknown error occurred."));
      }
    }
  };

export const fetchUserProfile =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_USER_PROFILE });
    try {
      const response = await axios.get(`${BASE_API_URL}/agent/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: FETCH_USER_PROFILE_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error)) {
        // Now TypeScript understands 'error' as an AxiosError
        const message = error.response?.data?.message || error.message;
        const status = error.response?.status;
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: status,
            error: message,
          },
        });
      } else if (error instanceof Error) {
        // Handle generic errors
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: null,
            error: error.message,
          },
        });
      } else {
        // Handle cases where the caught 'error' might not be an Error object
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const fetchUserVpnState =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch({ type: FETCH_USER_VPN_STATE });
    try {
      const response = await axios.get(`${BASE_API_URL}/agent/users/me`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      dispatch({
        type: FETCH_USER_PROFILE_SUCCESS,
        payload: response.data.data[0],
      });
    } catch (error) {
      console.log("error", error);
      if (axios.isAxiosError(error)) {
        // Now TypeScript understands 'error' as an AxiosError
        const message = error.response?.data?.message || error.message;
        const status = error.response?.status;
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: status,
            error: message,
          },
        });
      } else if (error instanceof Error) {
        // Handle generic errors
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: null,
            error: error.message,
          },
        });
      } else {
        // Handle cases where the caught 'error' might not be an Error object
        dispatch({
          type: FETCH_USER_PROFILE_FAILURE,
          payload: {
            errorCode: null,
            error: "An unknown error occurred.",
          },
        });
      }
    }
  };

export const updateUserProfile =
  (updateData: UserData, token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: UPDATE_USER_PROFILE_REQUEST });
    try {
      const response = await axios.put(
        `${BASE_API_URL}/agent/users/me`,
        updateData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Assuming your API returns the updated user data on successful update
      if (response.data && response.data.code === 200) {
        dispatch({
          type: UPDATE_USER_PROFILE_SUCCESS,
          payload: response.data.data[0],
        });
      } else {
        const currentTime = new Date().toLocaleString();

        dispatch({
          type: UPDATE_USER_PROFILE_FAILURE,
          payload: {
            error: "Failed to update user profile" + `: ${currentTime}`,
            errorCode: response.data.code,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: UPDATE_USER_PROFILE_FAILURE,
        payload: {
          error: "An unknown error occurred.",
          errorCode: error?.response?.status,
        },
      });
    }
  };

export const createIdentifier =
  (token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: UPDATE_USER_CERTIFICATE_REQUEST });
    try {
      const response = await axios.get(
        `${BASE_API_URL}/agent/create-certificate`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Assuming your API returns the updated user data on successful update
      if (response.data && response.data.code === 200) {
        dispatch({
          type: UPDATE_USER_PROFILE_SUCCESS,
          payload: response.data.data[0],
        });
      } else {
        const currentTime = new Date().toLocaleString();

        dispatch({
          type: UPDATE_USER_PROFILE_FAILURE,
          payload: {
            error: "Failed to update user profile" + `: ${currentTime}`,
            errorCode: response.data.code,
          },
        });
      }
    } catch (error: any) {
      dispatch({
        type: UPDATE_USER_PROFILE_FAILURE,
        payload: {
          error: "An unknown error occurred.",
          errorCode: error?.response?.status,
        },
      });
    }
  };

export const createApiKey =
  (token: string): AppThunk =>
  async (dispatch) => {
    dispatch({ type: UPDATE_USER_PROFILE_REQUEST });
    try {
      const response = await axios.post(
        `${BASE_API_URL}/agent/generate-api-key`,
        {}, // Pass an empty object as the request body
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Assuming your API returns the updated user data on successful update
      if (response.data && response.data.code === 200) {
        dispatch({
          type: UPDATE_USER_PROFILE_SUCCESS,
          payload: response.data.data[0],
        });
      } else {
        const currentTime = new Date().toLocaleString();

        dispatch({
          type: UPDATE_USER_PROFILE_FAILURE,
          payload: {
            error: "Failed to update user profile" + `: ${currentTime}`,
            errorCode: response.data.code,
          },
        });
      }
    } catch (error: any) {
      console.log(error);
      dispatch({
        type: UPDATE_USER_PROFILE_FAILURE,
        payload: {
          error: "An unknown error occurred.",
          errorCode: error?.response?.status,
        },
      });
    }
  };
