export const BASE_API_URL = "https://api.civicsync.com";
export const CLIENT_ID = "SyNShgApZNg2ZJ7GgKf0gM8xSO8h1xBm"; // Your Auth0 client ID
export const DOMAIN = "auth.civicsync.com"; // Your Auth0 domain
export const API_AUDIENCE = "civicsync";
export const WEBSOCKET_URL = "wss://api.civicsync.com";
export const AUTH0_ROLES_KEY = "civicsync/roles"
export const CIVICSYNC_ADMIN = "admin"
export const CIVICSYNC_CUSTOMER = "customer"

// export const BASE_API_URL = "http://127.0.0.1:8000";
// export const DOMAIN = "auth-dev.civicsync.com"; // Your Auth0 domain
// // export const CLIENT_ID = "LwAtVGgyu5AOVKTtKqIFhxb8RFqpAYoH"; // Your Auth0 client ID
// export const CLIENT_ID="kjOdtKwJghix4r5JvU0HyE49bdeFEu2R"
// // export const API_AUDIENCE = "https://dev-v60wyelemrai3kzd.us.auth0.com/api/v2/";
// export const API_AUDIENCE = "civicsync";
// export const WEBSOCKET_URL = "ws://127.0.0.1:8000";
// export const AUTH0_ROLES_KEY = "civicsync/roles"
// export const CIVICSYNC_ADMIN = "admin"
// export const CIVICSYNC_CUSTOMER = "customer"